
/**
|--------------------------------------------------
| CHECK USER LOGIN
|--------------------------------------------------
*/
export default function is_login() {
    try { //TODO: remove this method asap
        const access_token = localStorage.getItem('access_token');
        const token_type = localStorage.getItem('token_type');
        const roles = localStorage.getItem('roles');

        return access_token !== null && token_type !== null && roles !== null;
    } catch (error) {
        return false;
    }
}
