/**
 |--------------------------------------------------
 | DEFAULT TYPE ACTION
 |--------------------------------------------------
 */

// AUTHENTICATION
export const LOGIN_BY_EMAIL = 'LOGIN_BY_EMAIL';
// export const LOGIN_BY_GOOGLE = 'LOGIN_BY_GOOGLE';
export const LOGOUT = 'LOGOUT';
export const REGISTER_BY_EMAIL = 'REGISTER_BY_EMAIL';
export const REGISTER_BY_GOOGLE = 'REGISTER_BY_GOOGLE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_FUNBOT = 'GET_USER_INFO_FUNBOT';
export const GET_FANPAGE_FUNBOT = 'GET_FANPAGE_FUNBOT';
export const DETAIL_FANPAGE_FUNBOT = 'DETAIL_FANPAGE_FUNBOT';
export const CREATE_FANPAGE_FUNBOT = 'CREATE_FANPAGE_FUNBOT';
export const UPDATE_FANPAGE_FUNBOT = 'UPDATE_FANPAGE_FUNBOT';
export const DELETE_FANPAGE_FUNBOT = 'DELETE_FANPAGE_FUNBOT';
export const FAILURE_CREATE_FANPAGE_FUNBOT = 'FAILURE_CREATE_FANPAGE_FUNBOT';
export const FAILURE_DELETE_FANPAGE_FUNBOT = 'FAILURE_DELETE_FANPAGE_FUNBOT';
export const GET_APP_FUNBOT = 'GET_APP_FUNBOT';
export const FAILURE_REGISTER_BY_EMAIL = 'FAILURE_REGISTER_BY_EMAIL';
export const FAILURE_LOGIN_BY_EMAIL = 'FAILURE_LOGIN_BY_EMAIL';
export const FAILURE_TOKEN = 'FAILURE_TOKEN';
export const GET_KEYWORD_FUNBOT = 'GET_KEYWORD_FUNBOT';
export const ROLES = 'ROLES';
export const GET_ROLES = 'GET_ROLES';
export const CREATE_KEYWORD_FUNBOT = 'CREATE_KEYWORD_FUNBOT';
export const FAILURE_CREATE_KEYWORD_FUNBOT = 'FAILURE_CREATE_KEYWORD_FUNBOT';
export const FAILURE_KEYWORD_FUNBOT = 'FAILURE_KEYWORD_FUNBOT';
export const ADD_KEYWORDS_FANPAGE_FUNBOT = 'ADD_KEYWORDS_FANPAGE_FUNBOT';
export const FAILURE_ADD_KEYWORDS_FANPAGE_FUNBOT = 'FAILURE_ADD_KEYWORDS_FANPAGE_FUNBOT';
export const GET_DETAIL_USERS = 'GET_DETAIL_USERS';
export const ADD_ROLES_USER = 'ADD_ROLES_USER';
export const FAILURE_ADD_ROLES_USER = 'FAILURE_ADD_ROLES_USER';
export const GET_ENDPOINT_FUNBOT = 'GET_ENDPOINT_FUNBOT';
export const DETAIL_ENDPOINT_FUNBOT = 'DETAIL_ENDPOINT_FUNBOT';
export const REQUEST_CREATE_ENDPOINT = 'REQUEST_CREATE_ENDPOINT';
export const CREATE_ENDPOINT = 'CREATE_ENDPOINT';
export const FAILURE_CREATE_ENDPOINT = 'FAILURE_CREATE_ENDPOINT';
export const UPDATE_ENDPOINT = 'UPDATE_ENDPOINT';
export const FAILURE_UPDATE_ENDPOINT = 'FAILURE_UPDATE_ENDPOINT';
export const GET_ENDPOINT_OF_PAGE_FUNBOT = 'GET_ENDPOINT_OF_PAGE_FUNBOT';
export const IS_EDIT_ENDPOINT = 'IS_EDIT_ENDPOINT';
export const DELETE_ENDPOINT_PAGE = 'DELETE_ENDPOINT_PAGE';
export const FAILURE_DELETE_ENDPOINT_PAGE = 'FAILURE_DELETE_ENDPOINT_PAGE';
export const ADD_ENDPOINTS_FANPAGE_FUNBOT = 'ADD_ENDPOINTS_FANPAGE_FUNBOT';
export const FAILURE_ADD_ENDPOINTS_FANPAGE_FUNBOT = 'FAILURE_ADD_ENDPOINTS_FANPAGE_FUNBOT';
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS';
export const GET_APP_NOTIFY = 'GET_APP_NOTIFY';
export const GET_MODAL = 'GET_MODAL';
export const STATUS_DELETE_ENDPOINT_PAGE = 'STATUS_DELETE_ENDPOINT_PAGE';

export const UPLOAD_IMGUR_REQUEST = 'UPLOAD_IMGUR_REQUEST';
export const UPLOAD_IMGUR_SUCCESS = 'UPLOAD_IMGUR_SUCCESS';
export const UPLOAD_IMGUR_FAILURE = 'UPLOAD_IMGUR_FAILURE';
// CLEAR
export const CLEAR_STORE = 'CLEAR_STORE';
// USER
export const GET_ALL_USER = 'GET_ALL_USER';

//Fanpage
export const FANPAGE_GET_STARTED_STATUS_REQUEST = 'FANPAGE_GET_STARTED_STATUS_REQUEST';
export const FANPAGE_GET_STARTED_STATUS_FAILURE = 'FANPAGE_GET_STARTED_STATUS_FAILURE';
export const FANPAGE_GET_STARTED_STATUS_UPDATE = 'FANPAGE_GET_STARTED_STATUS_UPDATE';
export const FANPAGE_CREATE_V2_SUCCESS = 'FANPAGE_V2_CREATE_SUCCESS';
export const FANPAGE_CREATE_V2_REQUEST = 'FANPAGE_V2_CREATE_REQUEST';
export const FANPAGE_CREATE_V2_FAILURE = 'FANPAGE_V2_CREATE_FAILURE';
export const FANPAGE_CREATE_SUCCESS = 'FANPAGE_CREATE_SUCCESS';
export const FANPAGE_CREATE_REQUEST = 'FANPAGE_CREATE_REQUEST';
export const FANPAGE_CREATE_ERROR = 'FANPAGE_CREATE_ERROR';
export const FANPAGE_LIST_SUCCESS = 'FANPAGE_LIST_SUCCESS';
export const FANPAGE_LIST_REQUEST = 'FANPAGE_LIST_REQUEST';
export const FANPAGE_LIST_FAILURE = 'FANPAGE_LIST_FAILURE';
export const FANPAGE_DETAIL_SUCCESS = 'FANPAGE_DETAIL_SUCCESS';
export const FANPAGE_DETAIL_REQUEST = 'FANPAGE_DETAIL_REQUEST';
export const FANPAGE_DETAIL_FAILURE = 'FANPAGE_DETAIL_FAILURE';
export const FANPAGE_GET_USER_INBOX_REQUEST = 'FANPAGE_GET_USER_INBOX_REQUEST';
export const FANPAGE_GET_USER_INBOX_FAILURE = 'FANPAGE_GET_USER_INBOX_FAILURE';
export const FANPAGE_GET_USER_INBOX_SUCCESS = 'FANPAGE_GET_USER_INBOX_SUCCESS';

export const FANPAGE_EDIT_SUCCESS = 'FANPAGE_EDIT_SUCCESS';
export const FANPAGE_EDIT_REQUEST = 'FANPAGE_EDIT_REQUEST';
export const FANPAGE_EDIT_FAILURE = 'FANPAGE_EDIT_FAILURE';

export const FANPAGE_GET_HEARS_REQUEST = 'FANPAGE_GET_HEARS_REQUEST';
export const FANPAGE_GET_HEARS_SUCCESS = 'FANPAGE_GET_HEARS_SUCCESS';
export const FANPAGE_GET_HEARS_FAILURE = 'FANPAGE_GET_HEARS_FAILURE';
export const HEAR_DELETE_REQUEST = 'HEAR_DELETE_REQUEST';
export const HEAR_DELETE_SUCCESS = 'HEAR_DELETE_SUCCESS';
export const HEAR_DELETE_FAILURE = 'HEAR_DELETE_FAILURE';

export const REQUEST_NAME_FANPAGE_FUNBOT = 'REQUEST_NAME_FANPAGE_FUNBOT';
export const GET_NAME_FANPAGE_FUNBOT = 'GET_NAME_FANPAGE_FUNBOT';
export const FAILURE_NAME_FANPAGE_FUNBOT = 'FAILURE_NAME_FANPAGE_FUNBOT';

export const GET_LIST_POST_FANPAGE_V2_REQUEST = 'GET_LIST_POST_FANPAGE_V2_REQUEST';
export const GET_LIST_POST_FANPAGE_V2_SUCCESS = 'GET_LIST_POST_FANPAGE_V2_SUCCESS';
export const GET_LIST_POST_FANPAGE_V2_FAILURE = 'GET_LIST_POST_FANPAGE_V2_FAILURE';

export const FANPAGE_GET_ALL_SUCCESS = 'FANPAGE_GET_ALL_SUCCESS';
export const FANPAGE_GET_ALL_REQUEST = 'FANPAGE_GET_ALL_REQUEST';
export const FANPAGE_GET_ALL_FAILURE = 'FANPAGE_GET_ALL_FAILURE';

//Fanpage Post
export const FANPAGE_POST_LIST_REQUEST = 'FANPAGE_POST_LIST_REQUEST';
export const FANPAGE_POST_LIST_SUCCESS = 'FANPAGE_POST_LIST_SUCCESS';
export const FANPAGE_POST_LIST_ERROR = 'FANPAGE_POST_LIST_ERROR';
export const FANPAGE_POST_CREATE_REQUEST = 'FANPAGE_POST_CREATE_REQUEST';
export const FANPAGE_POST_CREATE_SUCCESS = 'FANPAGE_POST_CREATE_SUCCESS';
export const FANPAGE_POST_CREATE_ERROR = 'FANPAGE_POST_CREATE_ERROR';
export const FANPAGE_POST_UPDATE_REQUEST = 'FANPAGE_POST_UPDATE_REQUEST';
export const FANPAGE_POST_UPDATE_SUCCESS = 'FANPAGE_POST_UPDATE_SUCCESS';
export const FANPAGE_POST_UPDATE_ERROR = 'FANPAGE_POST_UPDATE_ERROR';
export const FANPAGE_POST_SUMMARY_REQUEST = 'FANPAGE_POST_SUMMARY_REQUEST';
export const FANPAGE_POST_SUMMARY_SUCCESS = 'FANPAGE_POST_SUMMARY_SUCCESS';
export const FANPAGE_POST_SUMMARY_ERROR = 'FANPAGE_POST_SUMMARY_ERROR';

// Fanpage Post review
export const FANPAGE_POST_REVIEW_LIST_REQUEST = 'FANPAGE_POST_REVIEW_LIST_REQUEST';
export const FANPAGE_POST_REVIEW_LIST_SUCCESS = 'FANPAGE_POST_REVIEW_LIST_SUCCESS';
export const FANPAGE_POST_REVIEW_LIST_ERROR = 'FANPAGE_POST_REVIEW_LIST_ERROR';
export const FANPAGE_POST_REVIEW_CREATE_REQUEST = 'FANPAGE_POST_REVIEW_CREATE_REQUEST';
export const FANPAGE_POST_REVIEW_CREATE_SUCCESS = 'FANPAGE_POST_REVIEW_CREATE_SUCCESS';
export const FANPAGE_POST_REVIEW_CREATE_ERROR = 'FANPAGE_POST_REVIEW_CREATE_ERROR';
export const FANPAGE_POST_REVIEW_DETAIL_REQUEST = 'FANPAGE_POST_REVIEW_DETAIL_REQUEST';
export const FANPAGE_POST_REVIEW_DETAIL_SUCCESS = 'FANPAGE_POST_REVIEW_DETAIL_SUCCESS';
export const FANPAGE_POST_REVIEW_DETAIL_ERROR = 'FANPAGE_POST_REVIEW_DETAIL_ERROR';
export const FANPAGE_POST_TOP_COMMENT_REQUEST = 'FANPAGE_POST_TOP_COMMENT_REQUEST';
export const FANPAGE_POST_TOP_COMMENT_SUCCESS = 'FANPAGE_POST_TOP_COMMENT_SUCCESS';
export const FANPAGE_POST_TOP_COMMENT_ERROR = 'FANPAGE_POST_TOP_COMMENT_ERROR';
export const FANPAGE_POST_REVIEW_EXPORT_REQUEST = 'FANPAGE_POST_REVIEW_EXPORT_REQUEST';
export const FANPAGE_POST_REVIEW_EXPORT_SUCCESS = 'FANPAGE_POST_REVIEW_EXPORT_SUCCESS';
export const FANPAGE_POST_REVIEW_EXPORT_ERROR = 'FANPAGE_POST_REVIEW_EXPORT_ERROR';
export const FANPAGE_POST_REVIEW_GIFTCODE_TRACKING_REQUEST = 'FANPAGE_POST_REVIEW_GIFTCODE_TRACKING_REQUEST';
export const FANPAGE_POST_REVIEW_GIFTCODE_TRACKING_SUCCESS = 'FANPAGE_POST_REVIEW_GIFTCODE_TRACKING_SUCCESS';
export const FANPAGE_POST_REVIEW_GIFTCODE_TRACKING_ERROR = 'FANPAGE_POST_REVIEW_GIFTCODE_TRACKING_ERROR';
export const FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_REQUEST = 'FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_REQUEST';
export const FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_SUCCESS = 'FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_SUCCESS';
export const FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_ERROR = 'FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_ERROR';
export const FANPAGE_POST_REVIEW_RESEND_GIFTCODE_REQUEST = 'FANPAGE_POST_REVIEW_RESEND_GIFTCODE_REQUEST';
export const FANPAGE_POST_REVIEW_RESEND_GIFTCODE_SUCCESS = 'FANPAGE_POST_REVIEW_RESEND_GIFTCODE_SUCCESS';
export const FANPAGE_POST_REVIEW_RESEND_GIFTCODE_ERROR = 'FANPAGE_POST_REVIEW_RESEND_GIFTCODE_ERROR';
export const SEND_MESSAGES_REQUEST = 'SEND_MESSAGES_REQUEST';
export const SEND_MESSAGES_SUCCESS = 'SEND_MESSAGES_SUCCESS';
export const SEND_MESSAGES_FAILURE = 'SEND_MESSAGES_FAILURE';
export const SEND_MESSAGES_STATUS_REQUEST = 'SEND_MESSAGES_STATUS_REQUEST';
export const SEND_MESSAGES_STATUS_SUCCESS = 'SEND_MESSAGES_STATUS_SUCCESS';
export const SEND_MESSAGES_STATUS_ERROR = 'SEND_MESSAGES_STATUS_ERROR';
export const RESEND_MESSAGES_REQUEST = 'RESEND_MESSAGES_REQUEST';
export const RESEND_MESSAGES_SUCCESS = 'RESEND_MESSAGES_SUCCESS';
export const RESEND_MESSAGES_ERROR = 'RESEND_MESSAGES_ERROR';
export const SUMMARY_SEND_MESSAGES_REQUEST = 'SUMMARY_SEND_MESSAGES_REQUEST';
export const SUMMARY_SEND_MESSAGES_SUCCESS = 'SUMMARY_SEND_MESSAGES_SUCCESS';
export const SUMMARY_SEND_MESSAGES_ERROR = 'SUMMARY_SEND_MESSAGES_ERROR';

//Fanpage Answer
export const FANPAGE_ANSWER_LIST_REQUEST = 'FANPAGE_ANSWER_LIST_REQUEST';
export const FANPAGE_ANSWER_LIST_SUCCESS = 'FANPAGE_ANSWER_LIST_SUCCESS';
export const FANPAGE_ANSWER_LIST_ERROR = 'FANPAGE_ANSWER_LIST_ERROR';
export const FANPAGE_ANSWER_CREATE_REQUEST = 'FANPAGE_ANSWER_CREATE_REQUEST';
export const FANPAGE_ANSWER_CREATE_SUCCESS = 'FANPAGE_ANSWER_CREATE_SUCCESS';
export const FANPAGE_ANSWER_CREATE_ERROR = 'FANPAGE_ANSWER_CREATE_ERROR';

//Post Answer
export const POST_ANSWER_LIST_REQUEST = 'POST_ANSWER_LIST_REQUEST';
export const POST_ANSWER_LIST_SUCCESS = 'POST_ANSWER_LIST_SUCCESS';
export const POST_ANSWER_LIST_ERROR = 'POST_ANSWER_LIST_ERROR';
export const POST_ANSWER_CREATE_REQUEST = 'POST_ANSWER_CREATE_REQUEST';
export const POST_ANSWER_CREATE_SUCCESS = 'POST_ANSWER_CREATE_SUCCESS';
export const POST_ANSWER_CREATE_ERROR = 'POST_ANSWER_CREATE_ERROR';
export const ANSWER_UPDATE_REQUEST = 'ANSWER_UPDATE_REQUEST';
export const ANSWER_UPDATE_SUCCESS = 'ANSWER_UPDATE_SUCCESS';
export const ANSWER_UPDATE_ERROR = 'ANSWER_UPDATE_ERROR';
export const ANSWER_DELETE_REQUEST = 'ANSWER_DELETE_REQUEST';
export const ANSWER_DELETE_SUCCESS = 'ANSWER_DELETE_SUCCESS';
export const ANSWER_DELETE_ERROR = 'ANSWER_DELETE_ERROR';
export const POST_ANSWER_COPY_REQUEST = 'POST_ANSWER_COPY_REQUEST';
export const POST_ANSWER_COPY_SUCCESS = 'POST_ANSWER_COPY_SUCCESS';
export const POST_ANSWER_COPY_ERROR = 'POST_ANSWER_COPY_ERROR';

//Post Seeding Keyword
export const POST_SEEDING_KEYWORD_LIST_REQUEST = 'POST_SEEDING_KEYWORD_LIST_REQUEST';
export const POST_SEEDING_KEYWORD_LIST_SUCCESS = 'POST_SEEDING_KEYWORD_LIST_SUCCESS';
export const POST_SEEDING_KEYWORD_LIST_ERROR = 'POST_SEEDING_KEYWORD_LIST_ERROR';
export const POST_SEEDING_KEYWORD_CREATE_REQUEST = 'POST_SEEDING_KEYWORD_CREATE_REQUEST';
export const POST_SEEDING_KEYWORD_CREATE_SUCCESS = 'POST_SEEDING_KEYWORD_CREATE_SUCCESS';
export const POST_SEEDING_KEYWORD_CREATE_ERROR = 'POST_SEEDING_KEYWORD_CREATE_ERROR';
export const SEEDING_KEYWORD_UPDATE_REQUEST = 'SEEDING_KEYWORD_UPDATE_REQUEST';
export const SEEDING_KEYWORD_UPDATE_SUCCESS = 'SEEDING_KEYWORD_UPDATE_SUCCESS';
export const SEEDING_KEYWORD_UPDATE_ERROR = 'SEEDING_KEYWORD_UPDATE_ERROR';
export const SEEDING_KEYWORD_DELETE_REQUEST = 'SEEDING_KEYWORD_DELETE_REQUEST';
export const SEEDING_KEYWORD_DELETE_SUCCESS = 'SEEDING_KEYWORD_DELETE_SUCCESS';
export const SEEDING_KEYWORD_DELETE_ERROR = 'SEEDING_KEYWORD_DELETE_ERROR';

//Hear
export const HEAR_DETAIL_REQUEST = 'HEAR_DETAIL_REQUEST';
export const HEAR_DETAIL_SUCCESS = 'HEAR_DETAIL_SUCCESS';
export const HEAR_DETAIL_FAILURE = 'HEAR_DETAIL_FAILURE';
export const HEAR_UPDATE_REQUEST = 'HEAR_UPDATE_REQUEST';
export const HEAR_UPDATE_SUCCESS = 'HEAR_UPDATE_SUCCESS';
export const HEAR_UPDATE_FAILURE = 'HEAR_UPDATE_FAILURE';
export const HEAR_FANPAGE_STARTED_ATTACH_FAILURE = 'HEAR_FANPAGE_STARTED_ATTACH_FAILURE';
export const HEAR_FANPAGE_STARTED_ATTACH_REQUEST = 'HEAR_FANPAGE_STARTED_ATTACH_REQUEST';
export const HEAR_FANPAGE_STARTED_ATTACH_SUCCESS = 'HEAR_FANPAGE_STARTED_ATTACH_SUCCESS';
export const HEAR_CREATE_REQUEST = 'HEAR_CREATE_REQUEST';
export const HEAR_CREATE_SUCCESS = 'HEAR_CREATE_SUCCESS';
export const HEAR_CREATE_FAILURE = 'HEAR_CREATE_FAILURE';
export const HEAR_FANPAGE_ATTACH_FAILURE = 'HEAR_FANPAGE_ATTACH_FAILURE';
export const HEAR_FANPAGE_ATTACH_REQUEST = 'HEAR_FANPAGE_ATTACH_REQUEST';
export const HEAR_FANPAGE_ATTACH_SUCCESS = 'HEAR_FANPAGE_ATTACH_SUCCESS';

// Hear report
export const HEAR_REPORT_REQUEST = 'HEAR_REPORT_REQUEST';
export const HEAR_REPORT_SUCCESS = 'HEAR_REPORT_SUCCESS';
export const HEAR_REPORT_FAILURE = 'HEAR_REPORT_FAILURE';

//notify
export const SEGMENT_LIST_SUCCESS = 'SEGMENT_LIST_SUCCESS';
export const SEGMENT_LIST_REQUEST = 'SEGMENT_LIST_REQUEST';
export const SEGMENT_LIST_FAILURE = 'SEGMENT_LIST_FAILURE';
export const SEGMENT_ADMIN_LIST_SUCCESS = 'SEGMENT_ADMIN_LIST_SUCCESS';
export const SEGMENT_ADMIN_LIST_REQUEST = 'SEGMENT_ADMIN_LIST_REQUEST';
export const SEGMENT_ADMIN_LIST_FAILURE = 'SEGMENT_ADMIN_LIST_FAILURE';
export const SEGMENT_CREATE_SUCCESS = 'SEGMENT_CREATE_SUCCESS';
export const SEGMENT_CREATE_FAILURE = 'SEGMENT_CREATE_FAILURE';
export const SEGMENT_CREATE_REQUEST = 'SEGMENT_CREATE_REQUEST';
export const APP_LIST_SUCCESS = 'APP_LIST_SUCCESS';
export const APP_LIST_REQUEST = 'APP_LIST_REQUEST';
export const APP_LIST_FAILURE = 'APP_LIST_FAILURE';
export const APP_WITHOUT_COMPANY_LIST_SUCCESS = 'APP_WITHOUT_COMPANY_LIST_SUCCESS';
export const APP_WITHOUT_COMPANY_LIST_REQUEST = 'APP_WITHOUT_COMPANY_LIST_REQUEST';
export const APP_WITHOUT_COMPANY_LIST_FAILURE = 'APP_WITHOUT_COMPANY_LIST_FAILURE';
export const APP_LIST_SEARCH_SUCCESS = 'APP_LIST_SEARCH_SUCCESS';
export const APP_LIST_SEARCH_REQUEST = 'APP_LIST_SEARCH_REQUEST';
export const APP_LIST_SEARCH_FAILURE = 'APP_LIST_SEARCH_FAILURE';
export const COMPANY_LIST_SUCCESS = 'COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_REQUEST = 'COMPANY_LIST_REQUEST';
export const COMPANY_LIST_FAILURE = 'COMPANY_LIST_FAILURE';
export const COMPANY_LIST_SEARCH_SUCCESS = 'COMPANY_LIST_SEARCH_SUCCESS';
export const COMPANY_LIST_SEARCH_REQUEST = 'COMPANY_LIST_SEARCH_REQUEST';
export const COMPANY_LIST_SEARCH_FAILURE = 'COMPANY_LIST_SEARCH_FAILURE';
export const COMPANY_APP_ACTION_REQUEST = 'COMPANY_APP_ACTION_REQUEST';
export const COMPANY_APP_ACTION_SUCCESS = 'COMPANY_APP_ACTION_SUCCESS';
export const COMPANY_APP_ACTION_FAILURE = 'COMPANY_APP_ACTION_FAILURE';
export const COMPANY_APP_LIST_SUCCESS = 'COMPANY_APP_LIST_SUCCESS';
export const COMPANY_APP_LIST_REQUEST = 'COMPANY_APP_LIST_REQUEST';
export const COMPANY_APP_LIST_FAILURE = 'COMPANY_APP_LIST_FAILURE';
export const APP_BY_KEY_LIST_SUCCESS = 'APP_BY_KEY_LIST_SUCCESS';
export const APP_BY_KEY_LIST_REQUEST = 'APP_BY_KEY_LIST_REQUEST';
export const APP_BY_KEY_LIST_FAILURE = 'APP_BY_KEY_LIST_FAILURE';
export const SEGMENT_GET_ONE_SUCCESS = 'SEGMENT_GET_ONE_SUCCESS';
export const SEGMENT_GET_ONE_REQUEST = 'SEGMENT_GET_ONE_REQUEST';
export const SEGMENT_GET_ONE_FAILURE = 'SEGMENT_GET_ONE_FAILURE';
export const SEGMENT_UPDATE_SUCCESS = 'SEGMENT_UPDATE_SUCCESS';
export const SEGMENT_UPDATE_FAILURE = 'SEGMENT_UPDATE_FAILURE';
export const SEGMENT_UPDATE_REQUEST = 'SEGMENT_UPDATE_REQUEST';
export const SEGMENT_APPROVE_SUCCESS = 'SEGMENT_APPROVE_SUCCESS';
export const SEGMENT_APPROVE_FAILURE = 'SEGMENT_APPROVE_FAILURE';
export const SEGMENT_APPROVE_REQUEST = 'SEGMENT_APPROVE_REQUEST';
export const SEGMENT_CANCEL_SUCCESS = 'SEGMENT_CANCEL_SUCCESS';
export const SEGMENT_CANCEL_FAILURE = 'SEGMENT_CANCEL_FAILURE';
export const SEGMENT_CANCEL_REQUEST = 'SEGMENT_CANCEL_REQUEST';

// Fanpage post from facebook
export const FANPAGE_POST_FROM_FACEBOOK_REQUEST = 'FANPAGE_POST_FROM_FACEBOOK_REQUEST';
export const FANPAGE_POST_FROM_FACEBOOK_SUCCESS = 'FANPAGE_POST_FROM_FACEBOOK_SUCCESS';
export const FANPAGE_POST_FROM_FACEBOOK_ERROR = 'FANPAGE_POST_FROM_FACEBOOK_ERROR';

// Fanpage menu
export const UPATE_FANPAGE_MENU_REQUEST = 'UPATE_FANPAGE_MENU_REQUEST';
export const UPATE_FANPAGE_MENU_SUCCESS = 'UPATE_FANPAGE_MENU_SUCCESS';
export const UPDATE_FANPAGE_MENU_ERROR = 'UPDATE_FANPAGE_MENU_ERROR';

// Facebook Rating Detail
export const FACEBOOK_RATING_DETAIL_REQUEST = 'FACEBOOK_RATING_DETAIL_REQUEST';
export const FACEBOOK_RATING_DETAIL_SUCCESS = 'FACEBOOK_RATING_DETAIL_SUCCESS';
export const FACEBOOK_RATING_DETAIL_ERROR = 'FACEBOOK_RATING_DETAIL_ERROR';

// Facebook Rating Summary
export const FACEBOOK_RATING_SUMMARY_REQUEST = 'FACEBOOK_RATING_SUMMARY_REQUEST';
export const FACEBOOK_RATING_SUMMARY_SUCCESS = 'FACEBOOK_RATING_SUMMARY_SUCCESS';
export const FACEBOOK_RATING_SUMMARY_ERROR = 'FACEBOOK_RATING_SUMMARY_ERROR';

//Email Notify
export const DOMAIN_CREATE_ERROR = 'DOMAIN_CREATE_ERROR';
export const DOMAIN_CREATE_REQUEST = 'DOMAIN_CREATE_REQUEST';
export const DOMAIN_CREATE_SUCCESS = 'DOMAIN_CREATE_SUCCESS';
export const DOMAIN_DETAIL_SUCCESS = 'DOMAIN_DETAIL_SUCCESS';
export const DOMAIN_DETAIL_FAILURE = 'DOMAIN_DETAIL_FAILURE';
export const DOMAIN_DETAIL_REQUEST = 'DOMAIN_DETAIL_REQUEST';
export const DOMAIN_EDIT_FAILURE = 'DOMAIN_EDIT_FAILURE';
export const DOMAIN_EDIT_REQUEST = 'DOMAIN_EDIT_REQUEST';
export const DOMAIN_EDIT_SUCCESS = 'DOMAIN_EDIT_SUCCESS';
export const TEMPLATE_CREATE_SUCCESS = 'TEMPLATE_CREATE_SUCCESS';
export const TEMPLATE_CREATE_ERROR = 'TEMPLATE_CREATE_ERROR';
export const TEMPLATE_CREATE_REQUEST = 'TEMPLATE_CREATE_REQUEST';
export const TEMPLATE_DETAIL_SUCCESS = 'TEMPLATE_DETAIL_SUCCESS';
export const TEMPLATE_DELETE_FAILURE = 'TEMPLATE_DELETE_FAILURE';
export const TEMPLATE_DELETE_REQUEST = 'TEMPLATE_DELETE_REQUEST';
export const TEMPLATE_DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS';
export const TEMPLATE_EDIT_SUCCESS = 'TEMPLATE_EDIT_SUCCESS';
export const TEMPLATE_EDIT_ERROR = 'TEMPLATE_EDIT_ERROR';
export const TEMPLATE_EDIT_REQUEST = 'TEMPLATE_EDIT_REQUEST';
export const TEMPLATE_LIST_SUCCESS = 'TEMPLATE_LIST_SUCCESS';
export const TEMPLATE_LIST_REQUEST = 'TEMPLATE_LIST_REQUEST';
export const TEMPLATE_LIST_FAILURE = 'TEMPLATE_LIST_FAILURE';
export const TELEGRAM_GROUP_LIST_SUCCESS = 'TELEGRAM_GROUP_LIST_SUCCESS';
export const TELEGRAM_GROUP_LIST_REQUEST = 'TELEGRAM_GROUP_LIST_REQUEST';
export const TELEGRAM_GROUP_LIST_FAILURE = 'TELEGRAM_GROUP_LIST_FAILURE';
export const USER_COLLECTION_LIST_SUCCESS = 'USER_COLLECTION_LIST_SUCCESS';
export const USER_COLLECTION_LIST_REQUEST = 'USER_COLLECTION_LIST_REQUEST';
export const USER_COLLECTION_LIST_FAILURE = 'USER_COLLECTION_LIST_FAILURE';
export const DOMAIN_NOTIFY_DETAIL_SUCCESS = 'DOMAIN_NOTIFY_DETAIL_SUCCESS';
export const DOMAIN_NOTIFY_EDIT_SUCCESS = 'DOMAIN_NOTIFY_EDIT_SUCCESS';
export const DOMAIN_CREATE_NOTIFY_SUCCESS = 'DOMAIN_CREATE_NOTIFY_SUCCESS';
export const DOMAIN_CREATE_NOTIFY_ERROR = 'DOMAIN_CREATE_NOTIFY_ERROR';
export const DOMAIN_CREATE_NOTIFY_REQUEST = 'DOMAIN_CREATE_NOTIFY_REQUEST';
export const DOMAIN_NOTIFY_LIST_REQUEST = 'DOMAIN_NOTIFY_LIST_REQUEST';
export const DOMAIN_NOTIFY_LIST_SUCCESS = 'DOMAIN_NOTIFY_LIST_SUCCESS';
export const DOMAIN_NOTIFY_LIST_FAILURE = 'DOMAIN_NOTIFY_LIST_FAILURE';
export const DOMAIN_NOTIFY_EMAIL_LIST_REQUEST = 'DOMAIN_NOTIFY_EMAIL_LIST_REQUEST';
export const DOMAIN_NOTIFY_EMAIL_LIST_SUCCESS = 'DOMAIN_NOTIFY_EMAIL_LIST_SUCCESS';
export const DOMAIN_NOTIFY_EMAIL_LIST_FAILURE = 'DOMAIN_NOTIFY_EMAIL_LIST_FAILURE';
export const DOMAIN_NOTIFY_EMAIL_DETAIL_SUCCESS = 'DOMAIN_NOTIFY_EMAIL_DETAIL_SUCCESS';
export const MY_COMPANIES_DOMAIN_LIST_REQUEST = 'MY_COMPANIES_DOMAIN_LIST_REQUEST';
export const MY_COMPANIES_DOMAIN_LIST_SUCCESS = 'MY_COMPANIES_DOMAIN_LIST_SUCCESS';
export const MY_COMPANIES_DOMAIN_LIST_FAILURE = 'MY_COMPANIES_DOMAIN_LIST_FAILURE';
export const MY_DOMAIN_LIST_REQUEST = 'MY_DOMAIN_LIST_REQUEST';
export const MY_DOMAIN_LIST_SUCCESS = 'MY_DOMAIN_LIST_SUCCESS';
export const MY_DOMAIN_LIST_FAILURE = 'MY_DOMAIN_LIST_FAILURE';
export const EMAIL_BLACKLIST_LIST_REQUEST = 'EMAIL_BLACKLIST_LIST_REQUEST';
export const EMAIL_BLACKLIST_LIST_SUCCESS = 'EMAIL_BLACKLIST_LIST_SUCCESS';
export const EMAIL_BLACKLIST_LIST_FAILURE = 'EMAIL_BLACKLIST_LIST_FAILURE';
export const EMAIL_BLACKLIST_UPDATE_ERROR = 'EMAIL_BLACKLIST_UPDATE_ERROR';
export const EMAIL_BLACKLIST_UPDATE_REQUEST = 'EMAIL_BLACKLIST_UPDATE_REQUEST';
export const EMAIL_BLACKLIST_UPDATE_SUCCESS = 'EMAIL_BLACKLIST_UPDATE_SUCCESS';
export const DOMAIN_LIST_REQUEST = 'DOMAIN_LIST_REQUEST';
export const DOMAIN_LIST_SUCCESS = 'DOMAIN_LIST_SUCCESS';
export const DOMAIN_LIST_ERROR = 'DOMAIN_LIST_ERROR';
export const EMAIL_BLACKLIST_LOG_REQUEST = 'EMAIL_BLACKLIST_LOG_REQUEST';
export const EMAIL_BLACKLIST_LOG_SUCCESS = 'EMAIL_BLACKLIST_LOG_SUCCESS';
export const EMAIL_BLACKLIST_LOG_ERROR = 'EMAIL_BLACKLIST_LOG_ERROR';
export const APPROVAL_TELEGRAM_GROUP_LIST_REQUEST = 'APPROVAL_TELEGRAM_GROUP_LIST_REQUEST';
export const APPROVAL_TELEGRAM_GROUP_LIST_SUCCESS = 'APPROVAL_TELEGRAM_GROUP_LIST_SUCCESS';
export const APPROVAL_TELEGRAM_GROUP_LIST_ERROR = 'APPROVAL_TELEGRAM_GROUP_LIST_ERROR';
export const APPROVAL_TELEGRAM_GROUP_CREATE_REQUEST = 'APPROVAL_TELEGRAM_GROUP_CREATE_REQUEST';
export const APPROVAL_TELEGRAM_GROUP_CREATE_SUCCESS = 'APPROVAL_TELEGRAM_GROUP_CREATE_SUCCESS';
export const APPROVAL_TELEGRAM_GROUP_CREATE_ERROR = 'APPROVAL_TELEGRAM_GROUP_CREATE_ERROR';
export const APPROVAL_TELEGRAM_GROUP_DETAIL_REQUEST = 'APPROVAL_TELEGRAM_GROUP_DETAIL_REQUEST';
export const APPROVAL_TELEGRAM_GROUP_DETAIL_SUCCESS = 'APPROVAL_TELEGRAM_GROUP_DETAIL_SUCCESS';
export const APPROVAL_TELEGRAM_GROUP_DETAIL_ERROR = 'APPROVAL_TELEGRAM_GROUP_DETAIL_ERROR';
export const APPROVAL_TELEGRAM_GROUP_UPDATE_REQUEST = 'APPROVAL_TELEGRAM_GROUP_UPDATE_REQUEST';
export const APPROVAL_TELEGRAM_GROUP_UPDATE_SUCCESS = 'APPROVAL_TELEGRAM_GROUP_UPDATE_SUCCESS';
export const APPROVAL_TELEGRAM_GROUP_UPDATE_ERROR = 'APPROVAL_TELEGRAM_GROUP_UPDATE_ERROR';
export const APPROVAL_TELEGRAM_GROUP_DELETE_REQUEST = 'APPROVAL_TELEGRAM_GROUP_DELETE_REQUEST';
export const APPROVAL_TELEGRAM_GROUP_DELETE_SUCCESS = 'APPROVAL_TELEGRAM_GROUP_DELETE_SUCCESS';
export const APPROVAL_TELEGRAM_GROUP_DELETE_ERROR = 'APPROVAL_TELEGRAM_GROUP_DELETE_ERROR';

// Redirect v2
export const SHORT_LINK_LIST_REQUEST = 'SHORT_LINK_LIST_REQUEST'
export const SHORT_LINK_LIST_SUCCESS = 'SHORT_LINK_LIST_SUCCESS'
export const SHORT_LINK_LIST_ERROR = 'SHORT_LINK_LIST_ERROR'
export const SHORT_LINK_CREATE_REQUEST = 'SHORT_LINK_CREATE_REQUEST'
export const SHORT_LINK_CREATE_SUCCESS = 'SHORT_LINK_CREATE_SUCCESS'
export const SHORT_LINK_CREATE_ERROR = 'SHORT_LINK_CREATE_ERROR'
export const SHORT_LINK_ASSIGN_ABILITY_REQUEST = 'SHORT_LINK_ASSIGN_ABILITY_REQUEST'
export const SHORT_LINK_ASSIGN_ABILITY_SUCCESS = 'SHORT_LINK_ASSIGN_ABILITY_SUCCESS'
export const SHORT_LINK_ASSIGN_ABILITY_ERROR = 'SHORT_LINK_ASSIGN_ABILITY_ERROR'
export const SHORT_LINK_USER_LIST_REQUEST = 'SHORT_LINK_USER_LIST_REQUEST'
export const SHORT_LINK_USER_LIST_SUCCESS = 'SHORT_LINK_USER_LIST_SUCCESS'
export const SHORT_LINK_USER_LIST_ERROR = 'SHORT_LINK_USER_LIST_ERROR'
export const SHORT_LINK_EDIT_REQUEST = 'SHORT_LINK_EDIT_REQUEST'
export const SHORT_LINK_EDIT_SUCCESS = 'SHORT_LINK_EDIT_SUCCESS'
export const SHORT_LINK_EDIT_ERROR = 'SHORT_LINK_EDIT_ERROR'
export const SHORT_LINK_DELETE_REQUEST = 'SHORT_LINK_DELETE_REQUEST'
export const SHORT_LINK_DELETE_SUCCESS = 'SHORT_LINK_DELETE_SUCCESS'
export const SHORT_LINK_DELETE_ERROR = 'SHORT_LINK_DELETE_ERROR'
export const SHORT_LINK_DETAIL_REQUEST = 'SHORT_LINK_DETAIL_REQUEST'
export const SHORT_LINK_DETAIL_SUCCESS = 'SHORT_LINK_DETAIL_SUCCESS'
export const SHORT_LINK_DETAIL_ERROR = 'SHORT_LINK_DETAIL_ERROR'

//Auth2
export const TEAM_LIST_REQUEST = 'TEAM_LIST_REQUEST';
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS';
export const TEAM_LIST_FAILURE = 'TEAM_LIST_FAILURE';
export const TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS';
export const TEAM_CREATE_ERROR = 'TEAM_CREATE_ERROR';
export const TEAM_CREATE_REQUEST = 'TEAM_CREATE_REQUEST';
export const TEAM_DETAIL_SUCCESS = 'TEAM_DETAIL_SUCCESS';
export const TEAM_EDIT_SUCCESS = 'TEAM_EDIT_SUCCESS';
export const TEAM_MEMBER_LIST_REQUEST = 'TEAM_MEMBER_LIST_REQUEST';
export const TEAM_MEMBER_LIST_SUCCESS = 'TEAM_MEMBER_LIST_SUCCESS';
export const TEAM_MEMBER_LIST_FAILURE = 'TEAM_MEMBER_LIST_FAILURE';
export const TEAM_MEMBER_ADD_ERROR = 'TEAM_MEMBER_ADD_ERROR';
export const TEAM_MEMBER_ADD_REQUEST = 'TEAM_MEMBER_ADD_REQUEST';
export const TEAM_MEMBER_ADD_SUCCESS = 'TEAM_MEMBER_ADD_SUCCESS';
export const TEAM_MEMBER_REMOVE_ERROR = 'TEAM_MEMBER_REMOVE_ERROR';
export const TEAM_MEMBER_REMOVE_REQUEST = 'TEAM_MEMBER_REMOVE_REQUEST';
export const TEAM_MEMBER_REMOVE_SUCCESS = 'TEAM_MEMBER_REMOVE_SUCCESS';
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

//SDK Notify
export const EMS_CORE_ADMINS = ['kazim@funtap.vn', 'chinhvt@funtap.vn', 'minhduc@funtap.vn'];
export const SDK_CORE_ADMINS = ['kazim@funtap.vn', 'minhlnh@funtap.vn'];
export const SDK_APPROVE_ADMINS = ['kazim@funtap.vn', 'minhlnh@funtap.vn', 'namch@funtap.vn', 'ryan@funtap.vn', 'ducchien@funtap.vn', 'linhptn@funtap.vn'];
export const SDK_ADMINS = [
    'kazim@funtap.vn',
    'minhlnh@funtap.vn',
    'minhduc@funtap.vn',
    'chinhvt@funtap.vn',
    'namch@funtap.vn',
    'ryan@funtap.vn',
    'ducchien@funtap.vn',
    'qsd@funtap.vn',
    'linhptn@funtap.vn',
    'trant@funtap.vn',
    'huyenpt@funtap.vn'
];

// Report api use
export const REPORT_API_USE_REQUEST = 'REPORT_API_USE_REQUEST';
export const REPORT_API_USE_SUCCESS = 'REPORT_API_USE_SUCCESS';
export const REPORT_API_USE_FAILURE = 'REPORT_API_USE_FAILURE';

// Report api do not use
export const REPORT_API_DO_NOT_USE_REQUEST = 'REPORT_API_DO_NOT_USE_REQUEST';
export const REPORT_API_DO_NOT_USE_SUCCESS = 'REPORT_API_DO_NOT_USE_SUCCESS';
export const REPORT_API_DO_NOT_USE_FAILURE = 'REPORT_API_DO_NOT_USE_FAILURE';

// Logs
export const LOGS_REQUEST = 'LOGS_REQUEST';
export const LOGS_SUCCESS = 'LOGS_SUCCESS';
export const LOGS_FAILURE = 'LOGS_FAILURE';

// Push Notify
export const PUSH_NOTIFY_REQUEST = 'PUSH_NOTIFY_REQUEST';
export const PUSH_NOTIFY_SUCCESS = 'PUSH_NOTIFY_SUCCESS';
export const PUSH_NOTIFY_FAILURE = 'PUSH_NOTIFY_FAILURE';

//Trigger
export const TRIGGER_CREATE_REQUEST = 'TRIGGER_CREATE_REQUEST';
export const TRIGGER_CREATE_SUCCESS = 'TRIGGER_CREATE_SUCCESS';
export const TRIGGER_CREATE_FAILURE = 'TRIGGER_CREATE_FAILURE';

export const TRIGGER_DETAIL_REQUEST = 'TRIGGER_DETAIL_REQUEST';
export const TRIGGER_DETAIL_SUCCESS = 'TRIGGER_DETAIL_SUCCESS';
export const TRIGGER_DETAIL_FAILURE = 'TRIGGER_DETAIL_FAILURE';

export const TRIGGER_UPDATE_REQUEST = 'TRIGGER_UPDATE_REQUEST';
export const TRIGGER_UPDATE_SUCCESS = 'TRIGGER_UPDATE_SUCCESS';
export const TRIGGER_UPDATE_FAILURE = 'TRIGGER_UPDATE_FAILURE';

export const TRIGGER_SUMMARY_REQUEST = 'TRIGGER_SUMMARY_REQUEST';
export const TRIGGER_SUMMARY_SUCCESS = 'TRIGGER_SUMMARY_SUCCESS';
export const TRIGGER_SUMMARY_FAILURE = 'TRIGGER_SUMMARY_FAILURE';

export const TRIGGER_SUMMARY_COUNTING_REQUEST = 'TRIGGER_SUMMARY_COUNTING_REQUEST';
export const TRIGGER_SUMMARY_COUNTING_SUCCESS = 'TRIGGER_SUMMARY_COUNTING_SUCCESS';
export const TRIGGER_SUMMARY_COUNTING_FAILURE = 'TRIGGER_SUMMARY_COUNTING_FAILURE';

export const TRIGGER_SUMMARY_STOP_REQUEST = 'TRIGGER_SUMMARY_STOP_REQUEST';
export const TRIGGER_SUMMARY_STOP_SUCCESS = 'TRIGGER_SUMMARY_STOP_SUCCESS';
export const TRIGGER_SUMMARY_STOP_FAILURE = 'TRIGGER_SUMMARY_STOP_FAILURE';

export const TRIGGER_SUMMARY_RESET_REQUEST = 'TRIGGER_SUMMARY_RESET_REQUEST';
export const TRIGGER_SUMMARY_RESET_SUCCESS = 'TRIGGER_SUMMARY_RESET_SUCCESS';
export const TRIGGER_SUMMARY_RESET_FAILURE = 'TRIGGER_SUMMARY_RESET_FAILURE';

//Game
export const GAME_UPDATE_REQUEST = 'GAME_UPDATE_REQUEST';
export const GAME_UPDATE_SUCCESS = 'GAME_UPDATE_SUCCESS';
export const GAME_UPDATE_FAILURE = 'GAME_UPDATE_FAILURE';

export const GAME_CREATE_REQUEST = 'GAME_CREATE_REQUEST';
export const GAME_CREATE_SUCCESS = 'GAME_CREATE_SUCCESS';
export const GAME_CREATE_FAILURE = 'GAME_CREATE_FAILURE';

export const GAME_DETAIL_REQUEST = 'GAME_DETAIL_REQUEST';
export const GAME_DETAIL_SUCCESS = 'GAME_DETAIL_SUCCESS';
export const GAME_DETAIL_FAILURE = 'GAME_DETAIL_FAILURE';

export const GAME_REQUEST = 'GAME_REQUEST';
export const GAME_SUCCESS = 'GAME_SUCCESS';
export const GAME_FAILURE = 'GAME_FAILURE';

export const GOOGLE_REVIEW_REQUEST = 'GOOGLE_REVIEW_REQUEST';
export const GOOGLE_REVIEW_SUCCESS = 'GOOGLE_REVIEW_SUCCESS';
export const GOOGLE_REVIEW_FAILURE = 'GOOGLE_REVIEW_FAILURE';

export const GOOGLE_REPLY_REQUEST = 'GOOGLE_REPLY_REQUEST';
export const GOOGLE_REPLY_SUCCESS = 'GOOGLE_REPLY_SUCCESS';
export const GOOGLE_REPLY_FAILURE = 'GOOGLE_REPLY_FAILURE';

export const GOOGLE_REPLY_DETAIL_REQUEST = 'GOOGLE_REPLY_DETAIL_REQUEST';
export const GOOGLE_REPLY_DETAIL_SUCCESS = 'GOOGLE_REPLY_DETAIL_SUCCESS';
export const GOOGLE_REPLY_DETAIL_FAILURE = 'GOOGLE_REPLY_DETAIL_FAILURE';

export const SEND_GIFT_CODE_REQUEST = 'SEND_GIFT_CODE_REQUEST';
export const SEND_GIFT_CODE_SUCCESS = 'SEND_GIFT_CODE_SUCCESS';
export const SEND_GIFT_CODE_FAILURE = 'SEND_GIFT_CODE_FAILURE';

export const INBOX_REQUEST = 'INBOX_REQUEST';
export const INBOX_SUCCESS = 'INBOX_SUCCESS';
export const INBOX_FAILURE = 'INBOX_FAILURE';

export const LIST_USER_OF_PAGE_REQUEST = 'LIST_USER_OF_PAGE_REQUEST';
export const LIST_USER_OF_PAGE_SUCCESS = 'LIST_USER_OF_PAGE_SUCCESS';
export const LIST_USER_OF_PAGE_FAILURE = 'LIST_USER_OF_PAGE_FAILURE';

export const LIST_MESSAGES_OF_USER_REQUEST = 'LIST_MESSAGES_OF_USER_REQUEST';
export const LIST_MESSAGES_OF_USER_SUCCESS = 'LIST_MESSAGES_OF_USER_SUCCESS';
export const LIST_MESSAGES_OF_USER_FAILURE = 'LIST_MESSAGES_OF_USER_FAILURE';

export const ASSIGN_USER_REQUEST = 'ASSIGN_USER_REQUEST';
export const ASSIGN_USER_SUCCESS = 'ASSIGN_USER_SUCCESS';
export const ASSIGN_USER_FAILURE = 'ASSIGN_USER_FAILURE';

//EMS
export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST';
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS';
export const GROUP_LIST_FAILURE = 'GROUP_LIST_FAILURE';
export const GROUP_CREATE_REQUEST = 'GROUP_CREATE_REQUEST';
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS';
export const GROUP_CREATE_FAILURE = 'GROUP_CREATE_FAILURE';
export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST';
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS';
export const GROUP_UPDATE_FAILURE = 'GROUP_UPDATE_FAILURE';
export const TELE_USER_UPDATE_REQUEST = 'TELE_USER_UPDATE_REQUEST';
export const TELE_USER_UPDATE_SUCCESS = 'TELE_USER_UPDATE_SUCCESS';
export const TELE_USER_UPDATE_FAILURE = 'TELE_USER_UPDATE_FAILURE';
export const CHAT_UPDATE_REQUEST = 'CHAT_UPDATE_REQUEST';
export const CHAT_UPDATE_SUCCESS = 'CHAT_UPDATE_SUCCESS';
export const CHAT_UPDATE_FAILURE = 'CHAT_UPDATE_FAILURE';

export const CHAT_LIST_REQUEST = 'CHAT_LIST_REQUEST';
export const CHAT_LIST_SUCCESS = 'CHAT_LIST_SUCCESS';
export const CHAT_LIST_FAILURE = 'CHAT_LIST_FAILURE';

export const MSG_LIST_REQUEST = 'MSG_LIST_REQUEST';
export const MSG_LIST_SUCCESS = 'MSG_LIST_SUCCESS';
export const MSG_LIST_FAILURE = 'MSG_LIST_FAILURE';
export const MSG_CREATE_REQUEST = 'MSG_CREATE_REQUEST';
export const MSG_CREATE_SUCCESS = 'MSG_CREATE_SUCCESS';
export const MSG_CREATE_FAILURE = 'MSG_CREATE_FAILURE';

export const MSG_SEGMENT_LIST_REQUEST = 'MSG_SEGMENT_LIST_REQUEST';
export const MSG_SEGMENT_LIST_SUCCESS = 'MSG_SEGMENT_LIST_SUCCESS';
export const MSG_SEGMENT_LIST_FAILURE = 'MSG_SEGMENT_LIST_FAILURE';
export const SEGMENT_MSG_DL_LIST_REQUEST = 'SEGMENT_MSG_DL_LIST_REQUEST';
export const SEGMENT_MSG_DL_LIST_SUCCESS = 'SEGMENT_MSG_DL_LIST_SUCCESS';
export const SEGMENT_MSG_DL_LIST_FAILURE = 'SEGMENT_MSG_DL_LIST_FAILURE';
export const MSG_SEGMENT_CREATE_REQUEST = 'MSG_SEGMENT_CREATE_REQUEST';
export const MSG_SEGMENT_CREATE_SUCCESS = 'MSG_SEGMENT_CREATE_SUCCESS';
export const MSG_SEGMENT_CREATE_FAILURE = 'MSG_SEGMENT_CREATE_FAILURE';


export const LIST_TAG_CONVERSATION_REQUEST = 'LIST_TAG_CONVERSATION_REQUEST';
export const LIST_TAG_CONVERSATION_SUCCESS = 'LIST_TAG_CONVERSATION_SUCCESS';
export const LIST_TAG_CONVERSATION_FAILURE = 'LIST_TAG_CONVERSATION_FAILURE';

export const LIST_TAG_REQUEST = 'LIST_TAG_REQUEST';
export const LIST_TAG_SUCCESS = 'LIST_TAG_SUCCESS';
export const LIST_TAG_FAILURE = 'LIST_TAG_FAILURE';

export const ADD_TAG_CONVERSATION_REQUEST = 'ADD_TAG_CONVERSATION_REQUEST';
export const ADD_TAG_CONVERSATION_SUCCESS = 'ADD_TAG_CONVERSATION_SUCCESS';
export const ADD_TAG_CONVERSATION_FAILURE = 'ADD_TAG_CONVERSATION_FAILURE';

export const FANPAGE_RANK_LIST_REQUEST = 'FANPAGE_RANK_LIST_REQUEST';
export const FANPAGE_RANK_LIST_SUCCESS = 'FANPAGE_RANK_LIST_SUCCESS';
export const FANPAGE_RANK_LIST_ERROR = 'FANPAGE_RANK_LIST_ERROR';

export const FANPAGE_RANK_CREATE_ERROR = 'FANPAGE_RANK_CREATE_ERROR';
export const FANPAGE_RANK_CREATE_REQUEST = 'FANPAGE_RANK_CREATE_REQUEST';
export const FANPAGE_RANK_CREATE_SUCCESS = 'FANPAGE_RANK_CREATE_SUCCESS';

export const RANK_FANPAGE_POST_LIST_ERROR = 'RANK_FANPAGE_POST_LIST_ERROR';
export const RANK_FANPAGE_POST_LIST_REQUEST = 'RANK_FANPAGE_POST_LIST_REQUEST';
export const RANK_FANPAGE_POST_LIST_SUCCESS = 'RANK_FANPAGE_POST_LIST_SUCCESS';

export const RANK_FANPAGE_POST_CREATE_REQUEST = 'RANK_FANPAGE_POST_CREATE_REQUEST';
export const RANK_FANPAGE_POST_CREATE_SUCCESS = 'RANK_FANPAGE_POST_CREATE_SUCCESS';
export const RANK_FANPAGE_POST_CREATE_ERROR = 'RANK_FANPAGE_POST_CREATE_ERROR';

export const RANK_PAGE_DELETE_POST_REQUEST = 'RANK_PAGE_DELETE_POST_REQUEST';
export const RANK_PAGE_DELETE_POST_SUCCESS = 'RANK_PAGE_DELETE_POST_SUCCESS';
export const RANK_PAGE_DELETE_POST_ERROR = 'RANK_PAGE_DELETE_POST_ERROR';

export const TRACKING_RANK_FANPAGE_LIST_ERROR = 'TRACKING_RANK_FANPAGE_LIST_ERROR';
export const TRACKING_RANK_FANPAGE_LIST_REQUEST = 'TRACKING_RANK_FANPAGE_LIST_REQUEST';
export const TRACKING_RANK_FANPAGE_LIST_SUCCESS = 'TRACKING_RANK_FANPAGE_LIST_SUCCESS';

export const FANPAGE_RANK_UPDATE_ERROR = 'FANPAGE_RANK_UPDATE_ERROR';
export const FANPAGE_RANK_UPDATE_REQUEST = 'FANPAGE_RANK_UPDATE_REQUEST';
export const FANPAGE_RANK_UPDATE_SUCCESS = 'FANPAGE_RANK_UPDATE_SUCCESS';

export const STATUS_USER_UPDATE_REQUEST = 'STATUS_USER_UPDATE_REQUEST';
export const STATUS_USER_UPDATE_SUCCESS = 'STATUS_USER_UPDATE_SUCCESS';
export const STATUS_USER_UPDATE_FAILURE = 'STATUS_USER_UPDATE_FAILURE';

export const GET_ME_INBOX_REQUEST = 'GET_ME_INBOX_REQUEST';
export const GET_ME_INBOX_SUCCESS = 'GET_ME_INBOX_SUCCESS';
export const GET_ME_INBOX_FAILURE = 'GET_ME_INBOX_FAILURE';

export const REPORT_CTV_REQUEST = 'REPORT_CTV_REQUEST';
export const REPORT_CTV_SUCCESS = 'REPORT_CTV_SUCCESS';
export const REPORT_CTV_FAILURE = 'REPORT_CTV_FAILURE';

export const REPORT_STAFF_REQUEST = 'REPORT_STAFF_REQUEST';
export const REPORT_STAFF_SUCCESS = 'REPORT_STAFF_SUCCESS';
export const REPORT_STAFF_FAILURE = 'REPORT_STAFF_FAILURE';

export const REPORT_TAG_REQUEST = 'REPORT_TAG_REQUEST';
export const REPORT_TAG_SUCCESS = 'REPORT_TAG_SUCCESS';
export const REPORT_TAG_FAILURE = 'REPORT_TAG_FAILURE';

export const REMOVE_TAGS_SUGGESTION_REQUEST = 'REMOVE_TAGS_SUGGESTION_REQUEST';
export const REMOVE_TAGS_SUGGESTION_SUCCESS = 'REMOVE_TAGS_SUGGESTION_SUCCESS';
export const REMOVE_TAGS_SUGGESTION_FAILURE = 'REMOVE_TAGS_SUGGESTION_FAILURE';

export const LIST_REPLY_STORE_REQUEST = 'LIST_REPLY_STORE_REQUEST';
export const LIST_REPLY_STORE_SUCCESS = 'LIST_REPLY_STORE_SUCCESS';
export const LIST_REPLY_STORE_FAILURE = 'LIST_REPLY_STORE_FAILURE';

export const REMOVE_REPLY_STORE_REQUEST = 'REMOVE_REPLY_STORE_REQUEST';
export const REMOVE_REPLY_STORE_SUCCESS = 'REMOVE_REPLY_STORE_SUCCESS';
export const REMOVE_REPLY_STORE_FAILURE = 'REMOVE_REPLY_STORE_FAILURE';

export const CREAT_REPLY_STORE_REQUEST = 'CREAT_REPLY_STORE_REQUEST';
export const CREAT_REPLY_STORE_SUCCESS = 'CREAT_REPLY_STORE_SUCCESS';
export const CREAT_REPLY_STORE_FAILURE = 'CREAT_REPLY_STORE_FAILURE';

export const UPDATE_REPLY_STORE_REQUEST = 'UPDATE_REPLY_STORE_REQUEST';
export const UPDATE_REPLY_STORE_SUCCESS = 'UPDATE_REPLY_STORE_SUCCESS';
export const UPDATE_REPLY_STORE_FAILURE = 'UPDATE_REPLY_STORE_FAILURE';

export const UPDATE_MULTI_CONVERSATION_REQUEST = 'UPDATE_MULTI_CONVERSATION_REQUEST';
export const UPDATE_MULTI_CONVERSATION_SUCCESS = 'UPDATE_MULTI_CONVERSATION_SUCCESS';
export const UPDATE_MULTI_CONVERSATION_FAILURE = 'UPDATE_MULTI_CONVERSATION_FAILURE';

export const REPORT_COLLAB_REQUEST = 'REPORT_COLLAB_REQUEST';
export const REPORT_COLLAB_SUCCESS = 'REPORT_COLLAB_SUCCESS';
export const REPORT_COLLAB_FAILURE = 'REPORT_COLLAB_FAILURE';

export const REPORT_DETAIL_GROUP_REQUEST = 'REPORT_DETAIL_GROUP_REQUEST';
export const REPORT_DETAIL_GROUP_SUCCESS = 'REPORT_DETAIL_GROUP_SUCCESS';
export const REPORT_DETAIL_GROUP_FAILURE = 'REPORT_DETAIL_GROUP_FAILURE';

export const GROUP_FANCHAT_LIST_FAILURE = 'GROUP_FANCHAT_LIST_FAILURE';
export const GROUP_FANCHAT_LIST_REQUEST = 'GROUP_FANCHAT_LIST_REQUEST';
export const GROUP_FANCHAT_LIST_SUCCESS = 'GROUP_FANCHAT_LIST_SUCCESS';

export const REMOVE_GROUP_REQUEST = 'REMOVE_GROUP_REQUEST';
export const REMOVE_GROUP_SUCCESS = 'REMOVE_GROUP_SUCCESS';
export const REMOVE_GROUP_FAILURE = 'REMOVE_GROUP_FAILURE';

export const CREAT_GROUP_REQUEST = 'CREAT_GROUP_REQUEST';
export const CREAT_GROUP_SUCCESS = 'CREAT_GROUP_SUCCESS';
export const CREAT_GROUP_FAILURE = 'CREAT_GROUP_FAILURE';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

//GOOGLE LOGIN
export const LOGIN_BY_GOOGLE_URL = 'LOGIN_BY_GOOGLE_URL';
export const FAILURE_LOGIN_BY_GOOGLE_URL = 'FAILURE_LOGIN_BY_GOOGLE_URL';
export const REQUEST_LOGIN_BY_GOOGLE_URL = 'REQUEST_LOGIN_BY_GOOGLE_URL';

export const REQUEST_LOGIN_BY_GOOGLE = 'REQUEST_LOGIN_BY_GOOGLE';
export const FAILURE_LOGIN_BY_GOOGLE = 'FAILURE_LOGIN_BY_GOOGLE';
export const LOGIN_BY_GOOGLE = 'LOGIN_BY_GOOGLE';

export const LOGIN_BY_GOOGLE_REQUEST = 'LOGIN_BY_GOOGLE_REQUEST';
export const LOGIN_BY_GOOGLE_SUCCESS = 'LOGIN_BY_GOOGLE_SUCCESS';
export const LOGIN_BY_GOOGLE_FAILURE = 'LOGIN_BY_GOOGLE_FAILURE';
